import React, { useEffect, useState } from "react";
import useStyles from "./Financial.styles";

import { Container, Box, TextField, MenuItem, Grid } from "@material-ui/core";
import FileItem from "../FileItem/FileItem";
import Annual2016 from "../../../../../../assets/files/laporan-keuangan-tahun-2016.pdf";
import Annual2017 from "../../../../../../assets/files/laporan-keuangan-tahun-2017.pdf";
import Annual2018 from "../../../../../../assets/files/laporan-keuangan-tahun-2018.pdf";
import Annual2019 from "../../../../../../assets/files/laporan-keuangan-tahun-2019.pdf";
import Annual2020 from "../../../../../../assets/files/laporan-keuangan-tahun-2020.pdf";
import Annual2021 from "../../../../../../assets/files/laporan-keuangan-tahun-2021.pdf";
import Annual2022 from "../../../../../../assets/files/laporan-keuangan-tahun-2022.pdf";
import Quartal12020 from "../../../../../../assets/files/laporan-keuangan-q1-2020.pdf";
import Quartal22020 from "../../../../../../assets/files/laporan-keuangan-q2-2020.pdf";
import Quartal32020 from "../../../../../../assets/files/laporan-keuangan-q3-2020.pdf";
import Quartal12021 from "../../../../../../assets/files/laporan-keuangan-q1-2021.pdf";
import Quartal22021 from "../../../../../../assets/files/laporan-keuangan-q2-2021.pdf";
import Quartal32021 from "../../../../../../assets/files/laporan-keuangan-q3-2021.pdf";
import Quartal12022 from "../../../../../../assets/files/laporan-keuangan-q1-2022.pdf";
import Quartal22022 from "../../../../../../assets/files/laporan-keuangan-q2-2022.pdf";
import Quartal32022 from "../../../../../../assets/files/laporan-keuangan-q3-2022.pdf";
import Quartal12023 from "../../../../../../assets/files/laporan-keuangan-q1-2023.pdf";
import Quartal1dan22023 from "../../../../../../assets/files/laporan-keuangan-tahun-semester-1-2023.pdf";
import Annual2023 from "../../../../../../assets/files/laporan-keuangan-tahun-2023.pdf";
import Quartal22024 from "../../../../../../assets/files/laporan-keuangan-q2-2024-un.pdf"

const items = [
  {
    name: "Laporan Keuangan Tahun 2016",
    file: Annual2016,
    type: "Annual",
    year: "2016",
  },
  {
    name: "Laporan Keuangan Tahun 2017",
    file: Annual2017,
    type: "Annual",
    year: "2017",
  },
  {
    name: "Laporan Keuangan Tahun 2018",
    file: Annual2018,
    type: "Annual",
    year: "2018",
  },
  {
    name: "Laporan Keuangan Tahun 2019",
    file: Annual2019,
    type: "Annual",
    year: "2019",
  },
  {
    name: "Laporan Keuangan Tahun 2020",
    file: Annual2020,
    type: "Annual",
    year: "2020",
  },
  {
    name: "Laporan Keuangan Tahun 2021",
    file: Annual2021,
    type: "Annual",
    year: "2021",
  },
  {
    name: "Laporan Keuangan Tahun 2022",
    file: Annual2022,
    type: "Annual",
    year: "2022",
  },
  {
    name: "Q1 Laporan Keuangan WEHA 2020",
    file: Quartal12020,
    type: "Quartal",
    year: "2020",
  },
  {
    name: "Q2 Laporan Keuangan WEHA 2020",
    file: Quartal22020,
    type: "Quartal",
    year: "2020",
  },
  {
    name: "Q3 Laporan Keuangan WEHA 2020",
    file: Quartal32020,
    type: "Quartal",
    year: "2020",
  },
  {
    name: "Q1 Laporan Keuangan WEHA 2021",
    file: Quartal12021,
    type: "Quartal",
    year: "2021",
  },
  {
    name: "Q2 Laporan Keuangan WEHA 2021",
    file: Quartal22021,
    type: "Quartal",
    year: "2021",
  },
  {
    name: "Q3 Laporan Keuangan WEHA 2021",
    file: Quartal32021,
    type: "Quartal",
    year: "2021",
  },
  {
    name: "Q1 Laporan Keuangan WEHA 2022",
    file: Quartal12022,
    type: "Quartal",
    year: "2022",
  },
  {
    name: "Q2 Laporan Keuangan WEHA 2022",
    file: Quartal22022,
    type: "Quartal",
    year: "2022",
  },
  {
    name: "Q3 Laporan Keuangan WEHA 2022",
    file: Quartal32022,
    type: "Quartal",
    year: "2022",
  },
  {
    name: "Q1 Laporan Keuangan WEHA 2023",
    file: Quartal12023,
    type: "Quartal",
    year: "2023",
  },
  {
    name: "Q1-Q2 Laporan Keuangan WEHA 2023",
    file: Quartal1dan22023,
    type: "Quartal",
    year: "2023",
  },
  {
    name: "Laporan Keuangan Tahun 2023",
    file: Annual2023,
    type: "Annual",
    year: "2023",
  },
  {
    name: "Q2 Laporan Keuangan Konsolidasi Tahun 2024 (Unaudited)",
    file: Quartal22024,
    type: "Quartal",
    year: "2024",
  },
];

const Financial = ({ filterType, filterYears }) => {
  const classes = useStyles();
  const [financialData, setFinancialData] = useState(items);
  const [financialFilter, setFinancialFilter] = useState({
    type: "",
    year: "",
  });
  const handleFilter = (data, props, type) => {
    setFinancialFilter({ ...financialFilter, [props]: data });
  };
  useEffect(() => {
    let tempData = items.filter((e) => {
      for (let key in financialFilter) {
        if (financialFilter[key] !== "" && e[key] !== financialFilter[key]) {
          return false; // Skip objects that don't match the filter condition
        }
      }
      return true; // Include objects that pass all filter conditions
    });
    setFinancialData(tempData);
  }, [financialFilter]);
  return (
    <Container maxWidth="xl">
      <Box textAlign={"right"} mb={4}>
        <Box mr={4} display={"inline"}>
          <TextField
            select
            className={classes.select}
            label="Type"
            value={financialFilter.type}
            onChange={(e) => handleFilter(e.target.value, "type")}
          >
            {filterType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box display={"inline"}>
          <TextField
            select
            className={classes.select}
            label="Tahun"
            value={financialFilter.year}
            onChange={(e) => handleFilter(e.target.value, "year")}
          >
            {filterYears.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {financialData.map((e, i) => {
          return (
            <Grid container key={i} item xs={6} sm={3} md={3} lg={2}>
              <FileItem data={e} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Financial;
